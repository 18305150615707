export const CONTENT_CARDS = (locale: string) => {
  const imageSrc =
    locale === 'hr'
      ? '/images/landing-reservations-card3.png'
      : '/images/showcase-owner3.jpg';

  return [
    {
      id: 1,
      title: 'landing.reservations.card1.title',
      description: 'landing.reservations.card1.description',
      img: '/images/landing-reservations-card1.png',
      noPadding: true,
    },
    {
      id: 2,
      title: 'landing.reservations.card2.title',
      description: 'landing.reservations.card2.description',
      img: '/images/landing-reservations-card2.png',
    },
    {
      id: 3,
      title: 'landing.reservations.card3.title',
      description: 'landing.reservations.card3.description',
      img: imageSrc,
    },
  ];
};
