import { FILTERS } from '@modules/directory/const';
import { directoryState } from '@modules/directory/state';
import { AnimatedText } from '@ui/AnimatedText';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import Arrow from 'public/icons/arrow-short-left-24.svg';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Slider, { CustomArrowProps, Settings } from 'react-slick';
import { useSetRecoilState } from 'recoil';

interface Props extends CustomArrowProps {
  isLeft?: boolean;
}

export const ArrowButton: React.FC<Props> = ({ isLeft, ...props }) => (
  <button
    className={clsx(
      'absolute cursor-pointer -bottom-72 w-40 h-40 bg-transparent border border-[#F8F8F81A] rounded-full flex justify-center items-center hover:border-text-6 transition-all duration-150 ease-out group z-10',
      isLeft ? 'left-[calc(50%-140px)]' : 'right-[calc(50%-140px)]',
    )}
    onClick={props.onClick}
  >
    <Arrow
      viewBox="0 0 24 24"
      className={clsx(
        'w-16 h-16 group-hover:[&>path]:fill-text-6 transition-all duration-150 ease-out',
        !isLeft && 'transform rotate-180',
      )}
    />
  </button>
);

const settings: Settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 1,
  variableWidth: true,
  arrows: true,
  appendDots: (dots) => (
    <div className="absolute -bottom-80 left-0 right-0 flex justify-center gap-4 pb-8">
      <ul className="max-w-max mx-auto"> {dots} </ul>
    </div>
  ),
  nextArrow: <ArrowButton />,
  prevArrow: <ArrowButton isLeft />,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        variableWidth: false,
      },
    },
  ],
};

const SPORTS = [
  'futsal',
  'squash',
  'tennis',
  'paintball',
  'martial-arts',
  'bubbleFootball',
  'airsoft',
];

export const Sports: FC = () => {
  const { formatMessage } = useIntl();
  const { push } = useRouter();
  const setFilter = useSetRecoilState(directoryState.directoryFilter);

  function handleOnClick(sport: string) {
    let filter = FILTERS.find((f) => f.sport === sport) || FILTERS[0];
    if (sport === 'futsal') filter = FILTERS[1];
    if (sport === 'martial-arts') filter = FILTERS[4];
    setFilter(filter);
    push('/directory');
  }

  return (
    <section className="grid grid-cols-12 mb-48 px-16 lg:px-48 overflow-hidden pt-[120px] pb-100">
      <AnimatedText
        className="mx-auto col-[1/13] md:col-[1/13] flex justify-center text-center text-3xlarge-fluid text-text-6 font-bold -tracking-[0.03em] mb-8"
        wrapperElement="h2"
        text={formatMessage({ id: 'landing.sports.title' })}
      />
      <AnimatedText
        className="col-[1/13] md:col-[4/10] text-center flex justify-center text-text-4 text-small-fluid mb-48 max-w-xl mx-auto"
        wrapperElement="p"
        text={formatMessage({ id: 'landing.sports.description' })}
      />
      <motion.div
        className="col-[1/13] w-full xsm:w-[calc(100%+32px)] lg:w-[calc(100%+96px)] ml-0 lg:-ml-24 slick-container"
        variants={{
          visible: { transition: { staggerChildren: 0.1 } },
        }}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <Slider {...settings}>
          {SPORTS.map((s, i) => (
            <motion.button
              className="px-10 group cursor-pointer"
              onClick={() => handleOnClick(s)}
              variants={{
                hidden: {
                  filter: 'blur(20px)',
                },
                visible: {
                  filter: 'blur(00px)',
                  transition: {
                    duration: 1,
                    ease: [0.65, 0.01, 0.02, 0.98],
                  },
                },
              }}
            >
              <div key={s} className="flex flex-col gap-24">
                <div className="relative overflow-hidden rounded-[20px]">
                  <Image
                    src={`/images/sports/${s}.jpg`}
                    alt={s}
                    width={360}
                    height={400}
                    className="rounded-[20px] max-xsm:w-full group-hover:scale-105 transition-all duration-150 ease-out"
                  />
                  <div className="absolute top-0 left-0 w-full h-full border border-background-1 bg-transparent rounded-[20px]" />
                </div>
                <h3 className="text-left font-bold text-text-5 text-xlarge -tracking-[0.03em] group-hover:text-primary transition-all duration-150 ease-out">
                  <FormattedMessage id={`landing.sports.${s}`} />
                </h3>
              </div>
            </motion.button>
          ))}
        </Slider>
      </motion.div>
    </section>
  );
};
