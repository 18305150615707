import { css } from '@emotion/react';
import { button } from '@styles/button.styles';
import { breakpoints } from '@styles/variables.styles';
import { AnimatedText } from '@ui/AnimatedText';
import { animations } from 'const';
import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import Stars from 'public/icons/stars.svg';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const BecomePartner: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <section className="flex flex-col md:flex-row items-center justify-center gap-32 mb-48 px-16 lg:px-48 py-[120px]">
      <div className="w-full max-w-[576px]">
        <motion.div
          className="flex gap-12 mb-16"
          {...animations.opacityInView({})}
        >
          <Stars viewBox="0 0 16 16" className="w-12 h-12" />
          <h3 className="text-primary text-small uppercase tracking-[3px]">
            <FormattedMessage id="landing.become-partner.sub-title" />
          </h3>
        </motion.div>
        <AnimatedText
          className="text-text-6 text-2xlarge sm:text-4xlarge font-bold mb-32"
          wrapperElement="h2"
          text={formatMessage({ id: 'landing.become-partner.title' })}
        />
        <AnimatedText
          className="text-base-fluid text-text-4 mb-48"
          wrapperElement="p"
          text={formatMessage({ id: 'landing.become-partner.description' })}
        />
        <motion.div {...animations.opacityInView({ delay: 0.6 })}>
          <Link href="/why-playoff" css={styles.button}>
            <span>
              <FormattedMessage id="landing.become-partner.more" />
            </span>
          </Link>
        </motion.div>
      </div>
      <motion.div
        className="w-full max-w-[672px]"
        initial={{
          filter: 'blur(20px)',
        }}
        whileInView={{
          filter: 'blur(0px)',
          transition: {
            duration: 1,
            ease: [0.65, 0.01, 0.02, 0.98],
            delay: 1,
          },
        }}
      >
        <Image
          src="/images/landing-mobile-screens.png"
          alt="Mobile screens"
          width={672}
          height={688}
        />
      </motion.div>
    </section>
  );
};

const styles = {
  button: css`
    ${button.primary};
    display: inline-flex;
    color: var(--color-general-primary);
    padding: 0px 32px;
    font-family: var(--font-family-primarySemiBold);
    font-size: 16px;
    line-height: 24px;
    height: 44px;

    color: var(--color-text-1);
    background-color: var(--color-general-primary);

    border-radius: 20px;

    @media ${breakpoints.toSml} {
      width: 100%;
    }

    &:hover,
    &:focus,
    &:active {
      color: var(--color-text-1);
    }
  `,
};
