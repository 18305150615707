import Bell from 'public/icons/bell.svg';
import Calendar from 'public/icons/calendar_calendar.svg';
import Card from 'public/icons/card.svg';
import Chart from 'public/icons/chart.svg';
import Clock from 'public/icons/clock-16.svg';
import Database from 'public/icons/database.svg';
import Dollar from 'public/icons/dollar.svg';
import Group from 'public/icons/group.svg';
import Lock from 'public/icons/lock.svg';
import Squares from 'public/icons/squares.svg';
import Stonks from 'public/icons/stonks.svg';
import Xcircle from 'public/icons/x-circle.svg';

import { AnimatedText } from '@ui/AnimatedText';
import { animations } from 'const';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styles } from './styles';

const firstCardItems = [
  {
    id: 'landing.features.card1.item1.title',
    icon: <Database viewBox="0 0 24 25" css={styles.icon} />,
  },
  {
    id: 'landing.features.card1.item2.title',
    icon: <Chart viewBox="0 0 24 25" css={styles.icon} />,
  },
  {
    id: 'landing.features.card1.item3.title',
    icon: <Stonks viewBox="0 0 24 25" css={styles.icon} />,
  },
  {
    id: 'landing.features.card1.item4.title',
    icon: <Group viewBox="0 0 16 16" css={styles.icon} />,
  },
  {
    id: 'landing.features.card1.item5.title',
    icon: <Card viewBox="0 0 24 25" css={styles.icon} />,
  },
  {
    id: 'landing.features.card1.item6.title',
    icon: <Calendar viewBox="0 0 16 16" css={styles.icon} />,
  },
  {
    id: 'landing.features.card1.item7.title',
    icon: <Clock viewBox="0 0 16 16" css={styles.icon} />,
  },
  {
    id: 'landing.features.card1.item8.title',
    icon: <Lock viewBox="0 0 16 16" css={styles.icon} />,
  },
  {
    id: 'landing.features.card1.item9.title',
    icon: <Bell viewBox="0 0 24 25" css={styles.icon} />,
  },
  {
    id: 'landing.features.card1.item10.title',
    icon: <Xcircle viewBox="0 0 24 25" css={styles.icon} />,
  },
];

const secondCardItems = [
  {
    id: 'landing.features.card2.item1.title',
    icon: <Squares viewBox="0 0 24 25" css={styles.icon} />,
  },
  {
    id: 'landing.features.card2.item2.title',
    icon: <Chart viewBox="0 0 24 25" css={styles.icon} />,
  },
  {
    id: 'landing.features.card2.item3.title',
    icon: <Dollar viewBox="0 0 24 25" css={styles.icon} />,
  },
  {
    id: 'landing.features.card2.item4.title',
    icon: <Calendar viewBox="0 0 16 16" css={styles.icon} />,
  },
  {
    id: 'landing.features.card2.item5.title',
    icon: <Group viewBox="0 0 16 16" css={styles.icon} />,
  },
  {
    id: 'landing.features.card2.item6.title',
    icon: <Bell viewBox="0 0 24 25" css={styles.icon} />,
  },
  {
    id: 'landing.features.card2.item7.title',
    icon: <Xcircle viewBox="0 0 24 25" css={styles.icon} />,
  },
  {
    id: 'landing.features.card2.item8.title',
    icon: <Clock viewBox="0 0 16 16" css={styles.icon} />,
  },
];

export const Features: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <section id="features">
      <div css={styles.base}>
        <motion.div
          css={styles.featuresCard}
          {...animations.yOpacityInView({
            y: 20,
          })}
        >
          <h2 css={styles.title}>
            <AnimatedText
              text={formatMessage({ id: 'landing.features.card1.title' })}
            />
          </h2>
          <p css={styles.paragraph}>
            <AnimatedText
              text={formatMessage({ id: 'landing.features.card1.paragraph' })}
            />
          </p>
          <motion.ul css={styles.featuresList}>
            {firstCardItems.map(({ id, icon }, i) => (
              <motion.li
                key={id}
                {...animations.yOpacityInView({ y: 20, delay: 0.1 * i })}
              >
                {icon}
                <FormattedMessage id={id} />
              </motion.li>
            ))}
          </motion.ul>
          <motion.div
            {...animations.opacityInView({ delay: 1 })}
            className="mt-auto"
          >
            <Link href="/why-playoff" css={styles.btn}>
              <FormattedMessage id="landing.features.btn" />
            </Link>
          </motion.div>
        </motion.div>
        <motion.div
          css={styles.featuresCard}
          {...animations.yOpacityInView({
            y: 20,
            delay: 0.3,
          })}
        >
          <h2 css={styles.title}>
            <AnimatedText
              text={formatMessage({ id: 'landing.features.card2.title' })}
            />
          </h2>
          <p css={styles.paragraph}>
            <AnimatedText
              text={formatMessage({ id: 'landing.features.card2.paragraph' })}
            />
          </p>

          <motion.ul css={styles.featuresList}>
            {secondCardItems.map(({ id, icon }, i) => (
              <motion.li
                key={id}
                {...animations.yOpacityInView({ y: 20, delay: 0.1 * i })}
              >
                {icon}
                <FormattedMessage id={id} />
              </motion.li>
            ))}
          </motion.ul>
          <motion.div
            {...animations.opacityInView({ delay: 1 })}
            className="mt-auto"
          >
            <Link href="/why-playoff" css={styles.btn}>
              <FormattedMessage id="landing.features.btn" />
            </Link>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};
