import { css } from '@emotion/react';
import { typo } from '@styles/typo.styles';
import { breakpoints } from '@styles/variables.styles';

const base = css`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 60px 16px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;

  @media ${breakpoints.fromMed} {
    flex-direction: row;
    align-items: unset;
  }

  @media ${breakpoints.fromLrg} {
    padding: 120px 64px;
  }
`;

const featuresCard = css`
  border: 1px solid;
  border-color: var(--color-border-cardBorder);
  max-width: 636px;
  width: 100%;
  border-radius: 20px;
  padding: 32px;
  background-color: var(--color-background-3);
  display: flex;
  flex-direction: column;
`;

const title = css`
  ${typo.from32to20}
  color: var(--color-text-white);
  font-weight: 700;
  margin-bottom: 12px;
`;

const paragraph = css`
  ${typo.from16to14}
  color: var(--color-text-5);
  margin-bottom: 24px;
`;

const featuresContent = css``;

const featuresList = css`
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: 100%;
  row-gap: 8px;
  column-gap: 12px;
  margin-bottom: 32px;

  li {
    ${typo.from16to14}
    display: flex;
    gap: 12px;
    padding: 12px 0;
    border-bottom: 1px solid;
    border-color: var(--color-border-cardBorder);
    width: 100%;
    color: var(--color-text-6);

    @media ${breakpoints.toSml} {
      max-width: 100%;
    }

    @media ${breakpoints.fromLrg} {
      max-width: calc(50% - 6px);
      width: 100%;
    }
  }
`;

const icon = css`
  width: 24px;
  height: 24px;
`;

const btn = css`
  ${typo.from16to14}
  background-color: transparent;
  border: 1px solid;
  border-color: var(--color-icon-1);
  padding: 10px 32px;
  border-radius: 24px;
  width: 100%;
  transition: all 0.2s ease-out;
  color: var(--color-general-primary);
  margin-top: auto;
  display: flex;
  justify-content: center;

  &:hover {
    color: var(--color-text-6);
    border-color: var(--color-border-7);
  }
`;

export const styles = {
  base,
  featuresCard,
  title,
  paragraph,
  featuresContent,
  featuresList,
  icon,
  btn,
};
