import { css } from '@emotion/react';
import { button } from '@styles/button.styles';
import { AnimatedText } from '@ui/AnimatedText';
import { animations } from 'const';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FaqItem } from './FaqItem';

const FAQList = (length: number, startNumber: number) =>
  Array.from({ length }).map((_, i) => {
    return {
      question: (
        <FormattedMessage id={`landing.faq.question${i + startNumber}`} />
      ),
      answer: <FormattedMessage id={`landing.faq.answer${i + startNumber}`} />,
    };
  });

export const FAQ: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <section className="grid grid-cols-12 max-w-[1600px] mx-auto mb-48 px-16 lg:px-48 overflow-hidden py-[120px]">
      <div className="col-[1/13] md:col-[2/12] flex justify-between items-cente mb-80 flex-wrap gap-40">
        <AnimatedText
          className="max-w-max text-3xlarge-fluid font-bold -tracking-[0.03em] text-text-6"
          wrapperElement="h2"
          text={formatMessage({ id: 'landing.faq.title' })}
        />
        <motion.div {...animations.opacityInView({ delay: 0.2 })}>
          <Link href="/contact" css={styles.button}>
            <FormattedMessage id="landing.faq.report" />
          </Link>
        </motion.div>
      </div>
      <div className="col-[1/13] md:col-[2/12] flex flex-col md:flex-row gap-40">
        <div className="w-full">
          <AnimatedText
            className="text-small text-primary tracking-[3px] mb-32 uppercase"
            wrapperElement="h3"
            text={formatMessage({ id: 'landing.faq.about-app' })}
          />
          <motion.ul
            className="w-full flex flex-col gap-32"
            initial="hidden"
            whileInView="visible"
            variants={{
              visible: {
                transition: { staggerChildren: 0.2 },
              },
            }}
          >
            {FAQList(5, 1).map((item, index) => (
              <FaqItem
                key={index}
                index={index}
                question={item.question}
                answer={item.answer}
              />
            ))}
          </motion.ul>
        </div>
        <div className="w-full">
          <AnimatedText
            className="text-small text-primary tracking-[3px] mb-32 uppercase"
            wrapperElement="h3"
            text={formatMessage({ id: 'landing.faq.reservations' })}
          />
          <motion.ul
            className="w-full flex flex-col gap-32"
            initial="hidden"
            whileInView="visible"
            variants={{
              visible: {
                transition: { staggerChildren: 0.2 },
              },
            }}
          >
            {FAQList(4, 6).map((item, index) => (
              <FaqItem
                key={index}
                index={index}
                question={item.question}
                answer={item.answer}
              />
            ))}
          </motion.ul>
        </div>
      </div>
    </section>
  );
};

const styles = {
  button: css`
    ${button.outline}
    font-size: 14px;
    line-height: 20px;
    padding: 12px 32px;
    height: 44px;
    font-family: var(--font-family-primarySemiBold);
    letter-spacing: -0.01em;
  `,
};
