import { css } from '@emotion/react';
import { grid } from '@styles/grid.styes';
import { typo } from '@styles/typo.styles';
import { breakpoints } from '@styles/variables.styles';

const base = css`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  @media ${breakpoints.fromLrg} {
    padding: 0 64px;
  }
`;

const wrapper = css`
  ${grid.basic6col};
  margin: 0;
  max-width: unset;
  background: #191720;
  width: 100%;

  padding: 60px 0;
  border-radius: 24px;

  @media ${breakpoints.fromLrg} {
    ${grid.basic24col};
    padding: 120px 0;
  }
`;

const title = css`
  ${typo.from56to32};
  grid-column: 1 / span 6;
  color: var(--color-text-6);
  text-align: center;
  font-weight: 700;
  margin-bottom: 48px;
  letter-spacing: -0.03em;
  padding: 0 16px;

  & > span {
    justify-content: center;
  }

  @media ${breakpoints.fromLrg} {
    grid-column: 5 / span 16;
  }
`;

const cardsContainer = css`
  grid-column: 1 / span 6;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 16px;

  @media ${breakpoints.fromLrg} {
    grid-column: 1 / span 24;
  }
`;

const contentContainer = css`
  grid-column: 1 / span 6;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;

  @media ${breakpoints.fromLrg} {
    grid-column: 1 / span 24;
  }
`;

const card = css`
  display: flex;
  gap: 16px;
  flex-direction: column;

  @media ${breakpoints.fromLrg} {
    margin-bottom: 64px;
  }
`;

const cardImage = css`
  border-radius: 8px;
`;

const cardTitle = css`
  ${typo.from20to16}
  color: var(--color-text-6);
  font-family: var(--font-family-primarySemiBold);
  margin-bottom: 4px;
`;

const cardDescription = css`
  color: var(--color-text-4);
  font-size: 14px;
  max-width: 280px;
`;

const imgContainer = css`
  background-color: #f4f8f70d;
  padding: 20px;
  border-radius: 16px;

  img {
    border-radius: 8px;
  }
`;

const imgContainerNoPadding = css`
  ${imgContainer}
  padding: 0;
`;

export const styles = {
  base,
  title,
  wrapper,
  cardsContainer,
  card,
  cardImage,
  cardTitle,
  cardDescription,
  contentContainer,
  imgContainer,
  imgContainerNoPadding,
};
