import { css } from '@emotion/react';
import { FacilityCard } from '@modules/directory/components/facility-card/FacilityCard';
import { useGetCsvFacilities } from '@modules/directory/hooks/useGetCsvFacilities';
import { button } from '@styles/button.styles';
import { breakpoints } from '@styles/variables.styles';
import { AnimatedText } from '@ui/AnimatedText';
import { animations } from 'const';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const Partners: FC = () => {
  const { facilities } = useGetCsvFacilities();
  const partners = useMemo(
    () => facilities.filter((f) => f.firebaseFacilityId),
    [facilities],
  );
  const { formatMessage } = useIntl();

  return (
    <section className="grid grid-cols-12 mb-48 overflow-hidden py-[120px]">
      <AnimatedText
        className="flex justify-center mx-auto col-[1/13] md:col-[1/13] px-16 lg:px-48 text-center text-3xlarge-fluid text-text-6 font-bold -tracking-[0.03em] mb-40"
        wrapperElement="h2"
        text={formatMessage({ id: 'landing.partners.title' })}
      />
      <div className="relative col-[1/13] w-full">
        <div className="w-full flex gap-24 overflow-auto flex-nowrap pb-20 px-32 md:px-[120px] max-w-min mx-auto scrollbar-thin">
          <div
            className="absolute -top-10 left-0 w-32 md:w-[120px] h-[300px] z-10"
            style={{
              background:
                'linear-gradient(93.03deg, #16141B 51.79%, rgba(22, 20, 27, 0.05) 94.33%)',
            }}
          />
          {partners.map((f, i) => (
            <motion.div
              className="min-w-[296px]"
              key={f.firebaseFacilityId}
              {...animations.opacityInView({ delay: 0.1 * i })}
            >
              <FacilityCard facility={f} />
            </motion.div>
          ))}
          <div
            className="absolute -top-10 right-0 w-32 md:w-[120px] h-[300px] z-10 rotate-180"
            style={{
              background:
                'linear-gradient(93.03deg, #16141B 51.79%, rgba(22, 20, 27, 0.05) 94.33%)',
            }}
          />
        </div>
      </div>
      <div className="col-[1/13] mx-auto mt-40 px-16 w-full flex justify-center">
        <motion.div {...animations.opacityInView({ delay: 0.5 })}>
          <Link css={styles.anchor} href="/partners">
            <FormattedMessage id="landing.partners.more" />
          </Link>
        </motion.div>
      </div>
    </section>
  );
};

const styles = {
  anchor: css`
    ${button.outline};
    display: inline-flex;
    padding: 0px 32px;
    font-family: var(--font-family-primarySemiBold);
    font-size: 16px;
    line-height: 24px;
    height: 44px;
    border-radius: 20px;

    @media ${breakpoints.toSml} {
      width: 100%;
    }
  `,
};
