import { AnimatedText } from '@ui/AnimatedText';
import { motion } from 'framer-motion';
import Quotation from 'public/icons/quotation.svg';
import Star from 'public/icons/star.svg';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const REVIEW_NAMES = [
  'Luka R.',
  'Marko V.',
  'Robert V.',
  'Antonio J.',
  'Demir T.',
  'Ivan A.',
  'Barbara',
  'Dražen P.',
  'Ivo N.',
];

export const Reviews: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <section className="grid grid-cols-12 mb-48 px-16 lg:px-48 max-w-[1600px] mx-auto py-[120px]">
      <AnimatedText
        className="flex justify-center col-[1/13] text-3xlarge-fluid text-text-6 font-bold -tracking-[0.03em] mb-40 mx-auto text-center"
        wrapperElement="h2"
        text={formatMessage({ id: 'landing.reviews.title' })}
      />
      <motion.ul
        className="col-[1/13] flex flex-wrap gap-32 justify-center"
        variants={{
          visible: { transition: { staggerChildren: 0.2 } },
        }}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        {REVIEW_NAMES.map((name, i) => (
          <motion.li
            key={name}
            className="flex flex-col gap-8 p-24 w-[412px] bg-[#F4F8F70D] rounded-xl"
            variants={{
              hidden: {
                filter: 'blur(20px)',
              },
              visible: {
                filter: 'blur(00px)',
                transition: {
                  duration: 1,
                  ease: [0.65, 0.01, 0.02, 0.98],
                },
              },
            }}
          >
            <div className="flex items-center gap-8">
              <div className="min-w-32 min-h-32 rounded-full bg-primary text-black flex justify-center items-center text-medium font-bold">
                {name.slice(0, 1)}
              </div>
              <p className="text-text-6 text-medium font-semibold">{name}</p>
            </div>
            <div className="flex gap-16 h-full">
              <Quotation className="min-w-12 min-h-16" viewBox="0 0 16 12" />
              <div className="flex flex-col gap-8 justify-between">
                <p className="text-text-5 text-base">
                  <FormattedMessage
                    id={`landing.reviews.description${i + 1}`}
                  />
                </p>
                <div className="flex">
                  {[...Array(5)].map((_, j) => (
                    <motion.div
                      key={j}
                      initial={{
                        scale: 0.5,
                      }}
                      animate={{ scale: 1 }}
                      exit={{ scale: 0.5 }}
                      transition={{
                        duration: 1,
                        delay: 1 + i * 0.1,
                        ease: [0.65, 0.01, 0.02, 0.98],
                      }}
                    >
                      <Star className="min-w-20 min-h-20" viewBox="0 0 20 21" />
                    </motion.div>
                  ))}
                  <p className="text-base text-text-6 font-semibold">5/5</p>
                </div>
              </div>
            </div>
          </motion.li>
        ))}
      </motion.ul>
    </section>
  );
};
