import { Banner } from '@ui/Banner';
import { Contact } from '@ui/Contact';
import { FC } from 'react';
import {
  BecomePartner,
  Community,
  FAQ,
  Features,
  Hero,
  Partners,
  Reservations,
  Reviews,
  Soon,
  Sports,
} from './fragments';

export const NewLandingPage: FC = () => {
  return (
    <>
      <Hero />
      <Reservations />
      <Sports />
      <BecomePartner />
      <Partners />
      <Features />
      <Community />
      <Soon />
      <Reviews />
      <Banner />
      <FAQ />
      <Contact />
    </>
  );
};
