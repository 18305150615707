import { AnimatedText } from '@ui/AnimatedText';
import { animations } from 'const';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CONTENT_CARDS } from './const';
import { styles } from './styles';

export const Reservations: FC = () => {
  const { locale, formatMessage } = useIntl();

  return (
    <section id="reservations" css={styles.base}>
      <div css={styles.wrapper}>
        <h2 css={styles.title}>
          <AnimatedText
            text={formatMessage({ id: 'landing.reservations.title' })}
            delay={0.2}
          />
        </h2>

        <div css={styles.cardsContainer}>
          <div css={styles.contentContainer}>
            {CONTENT_CARDS(locale)?.map(
              ({ id, title, description, img, noPadding }, i) => (
                <motion.div
                  css={styles.card}
                  key={id}
                  {...animations.opacityInView({ delay: 0.3 * i })}
                >
                  <div
                    css={[
                      noPadding
                        ? styles.imgContainerNoPadding
                        : styles.imgContainer,
                    ]}
                  >
                    <Image
                      src={img}
                      alt="Sport"
                      width={noPadding ? 320 : 280}
                      height={noPadding ? 200 : 160}
                      style={{ borderRadius: i === 2 ? '16px' : '8px' }}
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <h3 css={styles.cardTitle}>
                      <FormattedMessage id={title} />
                    </h3>
                    <p css={styles.cardDescription}>
                      <FormattedMessage id={description} />
                    </p>
                  </div>
                </motion.div>
              ),
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
