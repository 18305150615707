import { css } from '@emotion/react';
import { button } from '@styles/button.styles';
import { AnimatedText } from '@ui/AnimatedText';
import { animations } from 'const';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const Soon: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <section className="grid grid-cols-12 mb-48 px-16 lg:px-48 max-w-[1600px] mx-auto">
      <div className="col-[1/13] py-80 px-20 flex-col items-center justify-center bg-background-2 rounded-[24px]">
        <AnimatedText
          className="flex justify-center text-text-6 text-3xlarge-fluid -tracking-[0.03em] font-bold mb-32 text-center"
          wrapperElement="h2"
          text={formatMessage({ id: 'landing.soon.title' })}
        />

        <motion.ul
          initial="hidden"
          whileInView="visible"
          variants={{
            visible: {
              transition: { staggerChildren: 0.2 },
            },
          }}
          className="relative flex flex-wrap gap-x-16 gap-y-20 justify-center max-w-screen-3xl mx-auto mb-60 md:mb-72"
        >
          {[...Array(4)].map((_, i) => (
            <motion.li
              key={i}
              className="w-[300px] py-24 px-20 flex-col justify-center items-center gap-4 bg-[#F4F8F70D] border-gray-1 border rounded-xl"
              variants={{
                hidden: {
                  opacity: 0,
                },
                visible: {
                  opacity: 1,
                  transition: {
                    duration: 0.5,
                    ease: [0.65, 0.01, 0.02, 0.98],
                  },
                },
              }}
            >
              <h3 className="text-text-6 text-medium font-semibold text-center">
                <FormattedMessage id={`landing.soon.items.title${i + 1}`} />
              </h3>
              <p className="text-small text-text-4 text-center">
                <FormattedMessage
                  id={`landing.soon.items.description${i + 1}`}
                />
              </p>
            </motion.li>
          ))}

          <p className="min-w-max absolute -bottom-40 -translate-x-1/2 left-1/2 text-small text-primary">
            <FormattedMessage id="landing.soon.and-more" />
            ...
          </p>
        </motion.ul>
        <AnimatedText
          className="flex justify-center max-w-[512px] mx-auto text-text-4 text-small-fluid text-center mb-32"
          wrapperElement="p"
          text={formatMessage({ id: 'landing.soon.description' })}
        />
        <motion.div {...animations.opacityInView({ delay: 1, margin: '0px' })}>
          <Link href="/contact" css={styles.button}>
            <FormattedMessage id="landing.soon.contact" />
          </Link>
        </motion.div>
      </div>
    </section>
  );
};

const styles = {
  button: css`
    ${button.outlinePrimary}
    position: relative;
    font-size: 14px;
    line-height: 20px;
    padding: 12px 32px;
    font-family: var(--font-family-primarySemiBold);
    letter-spacing: -0.01em;
    margin: 0 auto;
    display: flex;
  `,
};
