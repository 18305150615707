import { SEO } from '@ui/SEO';
import { NewLandingPage } from '@views/new-landing-page';
import { promises as fs } from 'fs';
import type { GetStaticPropsContext, NextPage } from 'next';
import path from 'path';

interface Props {
  locale: {
    title: string;
    description: string;
  };
}

const HomePage: NextPage<Props> = ({ locale }) => {
  return (
    <>
      <SEO
        title={
          locale.title || 'PLAYOFF | Zaigraj s ekipom na bilo kojem terenu'
        }
        description={
          locale.description ||
          'Preuzmi PLAYOFF aplikaciju i u nekoliko sekundi rezerviraj bilo koji sportski teren. Bez poziva i sastanaka.'
        }
      />
      <NewLandingPage />
    </>
  );
};

export const getStaticProps = async ({ locale }: GetStaticPropsContext) => {
  const filePath = path.join(
    process.cwd(),
    `public/translations/${locale}.json`,
  );
  const jsonData = await fs.readFile(filePath, 'utf8');
  const data = JSON.parse(jsonData);

  return {
    props: {
      locale: {
        title: data['seo.title'],
        description: data['seo.description'],
      },
    },
  };
};

export default HomePage;
